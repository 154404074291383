import React, { ChangeEvent, FocusEvent, ForwardedRef, ReactElement, forwardRef } from 'react';
import { tv } from 'tailwind-variants';

type LightSwitchProps = {
  label: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  dataParentId?: string;
  id?: string;
};

const styling = tv({
  slots: {
    lightSwitch:
      'relative w-14 h-8 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[""] after:absolute after:top-[3px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[25px] after:w-[25px] after:transition-all dark:border-gray-600 peer-checked:bg-primary flex-shrink-0',
    lightSwitchContainer: 'pt-2 pb-2 border-b border-gray-400',
    lightSwitchInput: 'sr-only peer',
    lightSwitchLabel: 'inline-flex items-center cursor-pointer',
    lightSwitchText: 'ms-3',
  },
});

const LightSwitch = forwardRef<HTMLDivElement, LightSwitchProps>(
  ({ label, handleChange, checked, id, dataParentId }: LightSwitchProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { lightSwitch, lightSwitchContainer, lightSwitchLabel, lightSwitchInput, lightSwitchText } = styling();

    return (
      <div>
        <div className={`${lightSwitchContainer()} ${dataParentId ? 'pl-5' : ''}`}>
          <label htmlFor={`preference-${id}`} className={lightSwitchLabel()}>
            <input
              type="checkbox"
              className={lightSwitchInput()}
              id={`preference-${id}`}
              data-parent-id={dataParentId}
              onChange={handleChange}
              checked={checked}
            />
            <span className={lightSwitch()}>&nbsp;</span>
            <span className={lightSwitchText()}>{label}</span>
          </label>
        </div>
      </div>
    );
  },
);

LightSwitch.displayName = 'LightSwitch';

export default LightSwitch;
